.title {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: center;
}

.active_step_title {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #000000;
  margin: 10px 0;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.02em;
  color: #000000;
}

.inactive_step_title {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #a2a1a1;
  margin: 10px 0;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.02em;
  color: #a2a1a1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    height: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    height: 400px;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
    height: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    height: 55px;
  }
}

.coupon {
  width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn2 1s ease-out;

  gap: 15px;

  .coupon_box {
    width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    gap: 10px;
    margin-bottom: 10px;

    .btn {
      border-radius: 12px;
      box-sizing: border-box;
      width: 130px;
      margin: 0px 0px;
      margin-top: 5px;
      padding: 10px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      font-family: inherit;
      line-height: 20px;

      background: linear-gradient(90deg, #201f1e -0.34%, #495057 99.66%);
      border: none;
      color: #ffffff;
    }
  }
}

@keyframes noCoupon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.payment {
  width: 650px;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  gap: 15px;

  animation: noCoupon 0.75s ease-in;

  .payment_title {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: center;
  }
  .payment_text {
    font-weight: 700;
    font-size: 17px;
    text-transform: capitalize;
  }

  .content {
    display: flex;
    gap: 20px;
    width: 100%;

    .line {
      width: 2px;
      border-right: 1px solid #e0e0e0;
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      gap: 10px;

      .Label {
        display: none;
      }

      .column_title {
        font-weight: 700;
        font-size: 20px;
      }

      .sub_perks {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        gap: 5px;

        .dot {
          height: 3px;
          width: 3px;
          border-radius: 50%;
          display: inline-block;
          flex-shrink: 0;
          background-color: #000000;
        }

        .text {
          font-size: 12px;
          line-height: 13px;
        }
      }
      .price_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 4px;
        background: #f2f2f2;
        width: 100%;
        border: 3px solid #4e59e3;
        border-radius: 8px;
        .price {
          color: #4e59e3;
          font-size: 40px;
          font-weight: 600;
        }

        .charge {
          font-weight: 600;
          font-size: 15px;
          color: #818181;
        }

        .recurring_box {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          .recurring_text {
            font-weight: 500;
            font-size: 15px;
            color: #818181;
          }

          .recurring_price {
            font-weight: 600;
            font-size: 15px;
            text-align: right;
            color: #333333;
          }
        }
      }
    }
  }
}

.planDetails {
  font-family: Montserrat, sans-serif;
  border: 1px solid #9892FF;
  border-radius: 24px;
  background: #FFF;
  padding: 32px;

  .price {
    color: #4A68ED;

    font-size: 54px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: center;

    .pricePeriod {
      font-size: 32px;
      line-height: 38px;
    }

  }
  .backGuarantee {
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.014em;
    text-align: center;
    padding: 20px 0;
  }
  .subPerks {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
    border-top: 1px solid #E0E0E0;


    .infoCheck {
      padding-right: 20px;
      padding-top: 8px;
    }

    .infoText {
      font-weight: 400;
    }

  }
  .paymentDetails {
    background: #F8F8FE;
    padding: 16px;
    border-radius: 8px;

    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;

    display: flex;
    gap: 8px;
    flex-direction: column;

    .paymentDetailRow {
      display: flex;

      .paymentDetailIcon {
        padding-right: 3px;
        margin-right: 5px;
      }
    }
  }
}
