.btn {
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;
  max-width: 250px;
  margin: 15px 0;
  margin-top: 40px;
  padding: 16px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  font-family: inherit;
  line-height: 20px;

  border: none;
  color: var(--black-color);
  background: var(--yellow-color);
}

.footer {
  display: flex;
  color: #8792A2;
  margin-top: 20px;
  font-family: 'Roboto', 'sans-serif';
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.015em;

  .stripeLogo {
    padding: 0 15px;
    font-weight: 900;
  }

  .elementRight {
    border-left: 1px solid #8792A2;
    padding-left: 20px;

    a {
      padding-right: 10px;
    }
  }
}

.moneyBackGuarantee {
  color: #828282;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  max-width: 340px;
  font-size: 13px;
}
