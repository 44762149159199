.coupon_field {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;

  .inputs {
    display: flex;
    flex-direction: column;
    position: relative;

    .inputLabel {
      font-size: 14px;
      position: absolute;
      top: -20px;
      left: 5px;
    }

    .applied {
      color: rgba(0, 128, 0, 0.75);
    }
    .invalid {
      color: rgba(255, 0, 0, 0.75);
    }
  }

  .coupon_input {
    border: 1px solid #dbdbdb;
    font-family: inherit;
    border-radius: 10px;
    outline: none;
    font-size: 17px;
    padding: 5px;
  }

  .coupon_button {
    padding: 5px 15px;
    font-family: inherit;
    font-size: 17px;
    margin-left: 10px;
    border: none;
    background-color: #dbdbdb;
    border-radius: 10px;
  }

  .wrong_coupon {
    border: 1px solid red;
  }

  .applied_coupon {
    border: 1px solid green;
  }
}

.signup_coupon_field {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 5px;
  gap: 10px;

  .inputs {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;

    .inputLabel {
      font-size: 12px;
      position: absolute;
      top: -15px;
      left: 5px;
    }

    .applied {
      color: rgba(4, 192, 4, 0.75);
      font-size: 12px;
      font-weight: 500;
    }
    .invalid {
      color: red;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .coupon_input {
    padding: 10px 12px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }

  .coupon_button {
    border-radius: 12px;
    box-sizing: border-box;
    padding: 10px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: inherit;

    background: #c1c1c1;
    border: none;
    color: #ffffff;
  }
  .coupon_button_active {
    background: linear-gradient(90deg, #201f1e -0.34%, #495057 99.66%);
  }

  .wrong_coupon {
    border: 1px solid red;
  }

  .applied_coupon {
    border: 1px solid rgba(4, 192, 4, 0.75);
  }
}

.settings_field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  .inputs {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;

    .inputLabel {
      font-size: 12px;
      position: absolute;
      top: -20px;
      left: 5px;
    }

    .applied {
      color: rgba(4, 192, 4, 0.75);
      font-size: 14px;
      font-weight: 500;
    }
    .invalid {
      color: red;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .coupon_input {
    padding: 10px 12px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    font-size: 14px;

    width: 100%;
    font-family: 'Montserrat', sans-serif;
  }

  .coupon_button {
    display: flex;
    align-self: flex-start;
    text-align: center;
    text-transform: uppercase;

    font-weight: 700;
    font-size: 14px;
    font-family: inherit;
    line-height: 24px;
    color: #212529;
    background: #ffffff;

    border: 2px solid #000000;
    border-radius: 12px;

    padding: 10px 25px;
    margin: 20px 0;
  }
}
