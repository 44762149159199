.tooltip {
  & :global .MuiTooltip-tooltip {
    border-radius: 6px;
    background-color: var(--white-color);
    font-size: 16px;
    line-height: 18px;
    font-family: var(--font-family);
    font-weight: 500;
    padding: 6px 8px;
    max-width: 500px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.10);
  }
}

.arrow {
  color: var(--styled-tooltip-default);
}

.default {
  & :global .MuiTooltip-tooltip {
    padding: 16px;
    border-radius: 8px;
    font-weight: 400;
    color: var(--styled-tooltip-default);
  }
}

.clickable {
  & :global .MuiTooltip-tooltip {
    padding: 16px;
    border-radius: 8px;
    font-weight: 400;
    color: var(--styled-tooltip-default);
  }
}

.content {
  font-size: 16px;
  margin: 5px 3px;
  line-height: 24px;
}

.error {
  & :global .MuiTooltip-tooltip {
    border: 1px solid var(--styled-tooltip-error);
    color: var(--black-color);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  }
}
